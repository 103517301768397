
@import '../../../variables.scss';

.developersSection {
  background-color: #fdfdff;
  padding-top: 62px;
  .trustedLogosContainer {
    padding: 0 189px 125px;
    display: flex;
    div {
      // flex-grow: 1;
      flex-basis: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 185px;
      }
      &:first-child {
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .trustedLogosContainer {
    justify-content: space-around;
    div {
      flex-basis: 50% !important;
      justify-content: center !important;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .trustedLogosContainer {
    padding: 0 30px 125px !important;
    justify-content: space-between;
    div {
      &:nth-child(odd) {
        justify-content: flex-start !important;
      }
      &:nth-child(even) {
        justify-content: flex-end !important;
      }
      &:last-child {
        flex-basis: 100% !important;
        justify-content: center !important;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .trustedLogosContainer {
    div {
      flex-basis: 100% !important;
      justify-content: center !important;
      margin-bottom: 40px;
      &:nth-child(odd),
      &:nth-child(even) {
        justify-content: center !important;
      }
    }
  }
}