@import "../../variables.scss";

.cardContainer {
  @media screen and (max-width: 1520px) {
    // flex-basis: 50%;
    flex-basis: 33.3%;
    display: flex;
    justify-content: center;
    // margin-bottom: 40px;
    &:last-child {
      display: none;
    }
  }
  @media screen and (max-width: 1015px) {
    margin-bottom: 40px;
    &:nth-child(odd) {
      justify-content: flex-start;
    }
    &:nth-child(even) {
      justify-content: flex-end;
    }
    &:last-child {
      display: flex;
    }
  }
  @media screen and (max-width: 690px) {
    flex-basis: 100%;
    &:nth-child(odd),
    &:nth-child(even) {
      justify-content: center;
    }
  }
  .favorites {
    width: 20px;
    path {
      stroke: #fff;
      stroke-width: 40;
      transition: 0.2s all ease;
      fill: transparent;
    }
    &:hover {
      cursor: pointer;
      path {
        stroke: transparent;
        fill: $primary;
      }
    }
  }
  .cardStyle {
    width: 364px;
    box-shadow: none;    
    min-height: 430px;
    margin: auto;
    border: none !important;
    border-radius: 3px !important;
    // z-index: 1080;
    @media screen and (max-width: 1180px) {
      width: 320px;
    }
    @media screen and (max-width: 1015px) {
      margin: 0;
    }
    @media screen and (max-width: 770px) {
      width: 344px;
    }
    @media screen and (max-width: 730px) {
      width: 320px;
    }
    @media screen and (max-width: 690px) {
      width: 364px;
    }
    @media screen and (max-width: 400px) {
      width: 320px;
    }
    .cardImgContainer {
      height: 196px;
      width: 100%;
      position: relative;
      a{
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        border: none !important;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  // button {
  //   padding-top: 10px !important;
  //   margin: 0 !important;
  //   padding-bottom: 10px !important;
  // }
}
.cardBody {
  padding: 30px 31px;
  @media screen and (max-width: 1180px) {
    padding: 30px 11px;
  }
  @media screen and (max-width: 770px) {
    padding: 30px 11px;
  }
  @media screen and (max-width: 690px) {
    padding: 30px 31px;
  }
  @media screen and (max-width: 400px) {
    padding: 30px 11px;
  }
}
.priceAndDateContainer {
  * {
    line-height: 1;
  }
  display: flex;
  // flex-wrap: wrap;
  position: relative;
  margin-bottom: 57px;
  span {
    flex-grow: 1;
  }
  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -28px;
    width: 27px;
    height: 2px;
    background-color: $primary;
  }
}
.unitPrice {
  font-size: 18px;
  color: $text;
  letter-spacing: -0.02em;
  span {
    font-size: 20px;
    &:first-child {
      font-weight: bold;
      margin-bottom: 6px;
    }
    &:last-child {
      font-weight: 300;
      margin-left: 2px;
    }
  }
  p {
    font-size: 11px;
    // font-weight: 500;
    // color: #c3c4d0;
    color: #d3d3d9;
    margin-bottom: 0;
  }
  &.minMonthlyPayment {
    text-align: right;
  }
}
.unitDeliveryDateContainer {
  position: absolute;
  top: 31px;
  left: 31px;
  @media screen and (max-width: 1180px) {
    left: 11px;
  }
  @media screen and (max-width: 770px) {
    left: 11px;
  }
  @media screen and (max-width: 690px) {
    left: 31px;
  }
  @media screen and (max-width: 400px) {
    left: 11px;
  }
  .readyToMoveSpan {
    h6 {
      font-size: 10px;
      img {
        width: 12px;
        height: 12px;
        margin-right: 0.5rem;
      }
    }
  }
}
.unitDeliveryDate {
  display: inline-block;
  width: 83px;
  height: 26px;
  border-radius: 3px;
  padding-left: 10px;
  background-color: #fff;
  box-shadow: none;
  img {
    width: 10px;
    height: 9px;
    margin-right: 9px;
  }
  span {
    font-weight: 500;
    color: $text;
    font-size: 10px;
    letter-spacing: -0.02em;
  }
}
.topRight{
  position: absolute;
  top: 31px;
  right: 31px;
  width: 22px;
  height: 20px;
  @media screen and (max-width: 1180px) {
    right: 11px;
  }
  @media screen and (max-width: 770px) {
    right: 11px;
  }
  @media screen and (max-width: 690px) {
    right: 31px;
  }
  @media screen and (max-width: 400px) {
    right: 11px;
  }
  svg {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 0px 3px #000);
    path {
      stroke: #fff;
      stroke-width: 40;
      transition: 0.2s all ease;
      fill: transparent;
    }
  }
}
.unitInfo {
  display: flex;
  // flex-wrap: wrap;
  // color: #a8aabb;
  color: #adaebd;
  font-weight: 600;
  letter-spacing: -0.02em;
  // margin: 8px;
  margin-bottom: 23px;
  padding-bottom: 27px;
  border-bottom: 1px solid #f4f4f6;
  span {
    // flex-basis: content;
    flex-grow: 1;
    font-weight: 500;
    text-align: center;
    img {
      margin-right: 6px;
      height: auto;
    }
    span {
      font-size: 12px;
    }
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
}

.addressAndDeveloper {
  .unitAddress {
    width: 80%;
    display: inline-block;
    img {
      height: 15px;
      margin-right: 13px;
      margin-top: -2px;
    }
    p {
      display: inline-block;
      margin: 0;
      font-size: 12px;
      letter-spacing: -0.02em;
      color: $text;
      font-weight: bold;
    }
  }
  .developerLogo {
    display: inline-block;
    position: relative;
    width: 20%;
    height: 40px;
    text-align: right;
    img {
      // width: 50px;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
#bottomRight {
  position: absolute;
  bottom: 0px;
  right: 15px;
}

// @media screen and (max-width: 1024px) {
//   .cardContainer {
//     max-width: 100% !important;
//     flex: 0 0 100%;
//   }
// }

// @media screen and (max-width: 992px) {
//   .unitDeliveryDateContainer {
//     max-width: 100%;
//     text-align: left;
//     margin-top: 5px;
//   }
// }

// @media screen and (max-width: 767px) {
//   .cardContainer {
//     max-width: 50% !important;
//     flex: 0 0 50%;
//   }
//   .unitDeliveryDateContainer {
//     width: 20%;
//     text-align: right;
//     margin-top: 0;
//   }
// }
// @media screen and (max-width: 400px) {
//   .unitDeliveryDateContainer {
//     width: 100%;
//     text-align: left;
//     margin-top: 5px;
//     display: flex;

//     left: 2px !important;
//     top: 2px !important;
//   }
// }
// .topRight {
//   top: 20px;
//   right: 20px;
//   width: 22px;
// }
.favorite {
  // top: 20px;
  // right: 20px;
  path {
    transition: 0.2s all ease;
    fill: red !important;
  }
  &:hover {
    cursor: pointer;
    path {
      stroke: transparent;
      fill: #2419be;
    }
  }
}
