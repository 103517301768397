.carousel img {
  cursor: pointer;
}


.housePage .carousel-control-prev-icon {
  background-image: url("../../../assets/lessThan.png");
  width: 45px;
  height: 45px;
  background-color: inherit;
  position: relative;
}
.carousel-control-prev-custom {  
  position: absolute;
  left: 200px;
  bottom: 5px;
  width: 45px;
  height: 45px;
  z-index: 16;
}
.housePage .carousel-control-next-icon {
  background-image: url("../../../assets/greaterThan.png");
  width: 45px;
  height: 45px;
  position: relative;
  background-color: inherit;
}
.carousel-control-next-custom {  
  position: absolute;
  right: 200px;
  bottom: 5px;
  width: 45px;
  height: 45px;
  z-index: 16;
}

.housePage .carousel-indicators li {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.housePage .carousel-indicators li:active,
.housePage .carousel-indicators li:hover,
.housePage .carousel-indicators li:focus,
.housePage .carousel-indicators li:active {
  background-color: #2419be;
}

.housePage .carousel-item img {
  max-height: 400px;
  min-height: 400px;
}


.readyToMove {
  font-size: 10px;
}

.head-color {
  color: #191f4c;
}

.PhotoGalleyModal.modal-dialog {
  max-width: 1024px;
  margin-top: 8rem;
}

.PhotoGalleyModal.modal-dialog img {
  max-height: 400px;
}

.about-img img {
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
}

.about-img span {
  font-size: 14px;
  font-weight: bold;
  opacity: 0.6;
}