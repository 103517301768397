.Listings {
  height: calc(100vh - 212px);
  overflow-y: scroll;
  box-sizing: content-box;
  @media screen and (max-width: 992px) {
    height: auto;
  }
  // @media screen and (max-height: 830px) {
  //   height: auto;
  // }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.Listings::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE and Edge */
.Listings {
  -ms-overflow-style: none;
}

.selectedUnit {
  margin: auto;
  display: block;
}

