@import "../../../variables.scss";
@import "../../../custom.scss";

.searchContainer {
    width: 1196px;
    height: 105px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    left: 189px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0px 34px 62px 0px rgba(26, 27, 88, 0.05);
    .propertyType {
        width: 320px;
        height: calc(100% - 2px);
        display: inline-block;
        position: relative;
        div {
            width: 100%;
            height: 100%;
            margin: 0 !important;
        }
        button {
            width: 100%;
            border: none;
            outline: none;
            background-color: #fff;
            text-align: center;
            position: relative;
            padding: 0;
            height: 100%;
            svg,
            img {
                width: 16px;
                margin-right: 24px;
                fill: $primary;
                margin-top: -3px;
            }
            span {
                color: $text;
                font-weight: bold;
                font-size: 18px;
                letter-spacing:-0.02em;
            }
        }
        button::after {
            content: '\f107';
            color: #333;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            position: absolute;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
        }
        button[aria-expanded='true']::after {
            content: '\f106';
        }
        ul {
            list-style: none;
            background-color: #fff;
            padding: 0;
            padding: 20px;
            @include mainbtn-shadow();
            li {
                position: relative;
                margin-bottom: 5px;
                height: 35px;
                display: flex;
                align-items: center;
                input {
                    opacity: 0;
                    z-index: 1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
              span {
                    position: absolute;
                    // top: 3px;
                    left: 0;
                    height: 18px;
                    width: 18px;
                    background-color: transparent;
                    border-radius: 5px;
                    border: 1px solid $grey;
                    &::after {
                    position: absolute;
                    content: '';
                    left: 12px;
                    top: 12px;
                    height: 0;
                    width: 0;
                    border-radius: 5px;
                    border: solid #fff;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(0deg) scale(0);
                    -ms-transform: rotate(0deg) scale(0);
                    transform: rotate(0deg) scale(0);
                    opacity: 1;
                    }
                }
                p {
                    padding-left: 45px;
                    margin-bottom: 0;
                    line-height: 1.2;
                    overflow-wrap: break-word;
                    font-size: 21px;
                }
            }
            li input:checked ~ span {
              background-color: $primary;
              border-radius: 5px;
              -webkit-transform: rotate(0deg) scale(1);
              -ms-transform: rotate(0deg) scale(1);
              transform: rotate(0deg) scale(1);
              opacity: 1;
              border: 1px solid $primary;
              &::after {
                -webkit-transform: rotate(45deg) scale(1);
                -ms-transform: rotate(45deg) scale(1);
                transform: rotate(45deg) scale(1);
                opacity: 1;
                left: 6px;
                top: 1px;
                width: 4px;
                height: 12px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                background-color: transparent;
                border-radius: 0;
              }
            }
          }
        // ul {
        //     background-color: #fff;
        //     list-style: none;
        //     text-align: left;
        //     padding: 0;
        //     padding: 0 0 0 46px !important;
        //     li {
        //         cursor: pointer;
        //         input {
        //             margin-right: 26px !important;
        //             cursor: pointer;
        //         }
        //     }
        // }
        p {
            max-height: 119px;
            display: inline;
        }   
        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 24px;
            height: 71px;
            width: 2px;
            background-color: #ebebef;
        } 
    }
    .inputSearchContainer {
        height: 100%;
        width: 600px;
        display: inline-flex;
        align-items: center;
        input {
            border: none;
            // width: 100%;
            width: calc(100% - 89px);
            outline: none;
            font-size: 18px;
            // padding-left: 89px !important;
            // margin-top: 26px !important;
            // margin-bottom: 26px !important;
        }
        .locationIcon {
            width: 19px;
            height: 20px;
            fill: #2419be;
            position: relative;
            margin-left: 42px;
            margin-right: 28px;
            display: inline-block;
            // top: -74px;
            // left: 42px;
        }
    }
    .searchBtn {
        width: 244px;
        height: 66px;
        margin-right: 3rem;
        color: #fff;
        font-size: 19px;
        font-weight: bold;
        letter-spacing:-0.02em;
        background-color: $primary;
        text-transform: uppercase;
        outline: none;
        border: none;
        border-radius: 3px;
        box-shadow: 0px 10px 24px 0px rgba(36, 25, 190, 0.25);
        span {
            &:last-child {
                display: none;
            }
        }
    }
  }

  @media screen and (max-width: 1450px) {
    //   .searchContainer {
    //       margin: auto;
    //       width: 80%;
    //   }
        .inputSearchContainer {
            width: 40% !important;
            // width: calc(70% - 70px) !important;
        }
  }
  @media screen and (max-width: 1250px) {
    .searchContainer {
        width: 100%;
    }
    // .propertyType {
    //     width: 35% !important;
    // }
    .inputSearchContainer {
        // width: 40% !important;
        width: calc(70% - 70px) !important;
    }
    .searchBtn {
        width: 66px !important;
        padding: 0 10px;
        margin-left: auto;
        margin-right: 30px;
        span {
            &:first-child {
                display: none;
            }
            &:last-child {
                display: inline-block !important;
            }
        }
    }
  }

    @media screen and (max-width: 1100px) {
    .searchContainer {
        width: 75vw !important;
        margin: auto;
        // padding-right: 15px;
    }
    .propertyType {
        width: 25vw !important;
    }
    .inputSearchContainer {
        width: 30vw !important;
    }
    // .searchBtn {
    //     width: 66px !important;
    //     padding: 0 10px;
    //     margin-left: auto;
    //     span {
    //         &:first-child {
    //             display: none;
    //         }
    //         &:last-child {
    //             display: inline-block !important;
    //         }
    //     }
    // }
    }

    @media screen and (max-width: 1100px) {
        .propertyType {
            ul {
                padding: 20px 10px !important;
                li {
                    p {
                        padding-left: 30px !important;
                        font-size: 18px !important;
                    }
                }
            }
        }
    }

//   @media screen and (max-height: 870px) {
//     .searchContainer {
//         height: 100px;
//         .inputSearchContainer {
//             input {
//                 // margin-top: 15px !important;
//                 // margin-bottom: 15px !important;
//             }
//         }
//     }
//   }

// @media screen and (max-width: 1450px) {
//     .searchContainer {
//         width: 996px !important;
//     }
//     .inputSearchContainer {
//       width: 400px !important;
//     }
// }


@media screen and (max-width: 768px) {
    .searchContainer {
        height: 238px;
        flex-wrap: wrap;
    }
    .propertyType {
        flex-basis: 100%;
        width: 100% !important;
        height: 119px !important;
        margin-bottom: 0.5rem;
        border-top: 3px solid #2419be;
        &::after {
            display: none;
        }
    }
    .inputSearchContainer {
        order: -1;
        width: 100% !important;
        height: 119px !important;
    }
    .searchBtn {
        // order: -1;
        width: 100% !important;
        margin-right: 0 !important;
    }
}

// fix dropdown icon
@media (max-width: 860px) and (min-width: 770px) {
    .searchContainer {
        .propertyType {
            button::after { 
                right: 6px;
            }
        }
    }
}