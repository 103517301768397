

$theme-colors: (
  'primary': #2419be,
  'secondary': #F3F4F6,
);




@mixin mainbtn-shadow() {
    box-shadow: 0px 10px 24px 0px rgba(36, 25, 190, 0.25);
}

// btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold

.btn {
    @include mainbtn-shadow();
}

.btn:hover {
  color: transparent;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
