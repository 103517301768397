.testimonal {
  box-sizing: border-box;
  height: 907px;
  padding-top: 79px;
  background-color: #fdfdff;
  padding-bottom: 92px !important;
  .header {
    padding-top: 127px !important;
    margin-bottom: 100px;
    font-size: 31.91px;
    font-weight: bold;
    color: #202551;
    background-image: url('../../../assets/landingPage/headerBackGroundLogo2.png');
    background-repeat: no-repeat;
    background-position: center bottom 18px;
    background-size: 100px 75px;
  }
  .slideContainer {
    .card {
      border: 1px solid rgba($color: #000000, $alpha: 0.3);
      padding: 61px 147px 83px 116px;
      background-color: #ffffff;
      height: 476.75px;
      width: 891.91px;

      p.heading {
        padding-bottom: 40px !important;
        color: #191538;
      }
      p.body {
        padding-bottom: 59px !important;
        word-spacing: 8px !important;
        color: #191538;
      }
      p.footer {
        font-size: 18.04px;
        font-weight: 500px;
        word-spacing: 1.2rem;
        opacity: 0.6;
        color: #191538;
      }
    }
  }
 .slideContainer .slick-slide {
    margin-bottom: 78px;
    & > div {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
  }

 .slideContainer .slick-dots li {
    width: auto;
    height: auto;
  }
 .slideContainer .slick-dots li button {
    background-color: #2419be;
    opacity: 0.5;
    width: 13px;
    border: none;
    height: 7px;
  }
 .slideContainer .slick-dots li.slick-active button {
    background-color: #2419be;
    opacity: 1;
    width: 41px;
  }
 .slideContainer .slick-dots li button:before {
    content: none;
  }

 .slideContainer .slick-initialized .slick-slide {
    opacity: 0.5;
  }
 .slideContainer .slick-active {
    opacity: 1 !important;
  }
 .slideContainer .card img {
    width: 100px;
    border-radius: 50%;
    border: 1px solid #2419be;
    margin: auto;
    margin-bottom: 42px;
  }
}
