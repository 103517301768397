.GoogleMap .gm-style .gm-style-iw-c,
.GoogleMap .gm-style .gm-style-iw-d {
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}
.GoogleMap .gm-style .gm-style-iw-c button:not(:disabled),
.GoogleMap .gm-style .gm-style-iw-c [type="button"]:not(:disabled),
.GoogleMap .gm-style .gm-style-iw-c [type="reset"]:not(:disabled),
.GoogleMap .gm-style .gm-style-iw-c [type="submit"]:not(:disabled) {
  top: 1px !important;
  right: 1px !important;
}

.HouseImage {
  position: absolute;
}
.HouseImage:hover {
  opacity: 0.2;
}
.houseLink {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;

  background-color: transparent;
  color: transparent;
}
.houseLink span {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 18px;
  font-weight: bold;
}
.houseLink:hover {
  background-color: rgba(256, 256, 256, 0.7);
  color: #2419be;
}
@media (min-width: 20px) {
  .cardOnMap h5,
  .cardOnMap p,
  .cardOnMap span {
    font-size: 10px !important;
    padding: 2px;
    margin: 1px;
  }
  .HouseImage {
    position: relative;
    max-height: 100px !important;
  }
  .houseLink {
    left: 0;
  }
  .houseLink span {
    top: 50%;
  }
}
@media (min-width: 768px) {
  .gm-style-iw .gm-style-iw-c {
    padding: 2px !important;
    margin: 0 !important;
    font-size: 10px !important;
  }
  .gm-style-iw .gm-style-iw-c {
    max-width: 336px !important;
  }
  .gm-style .gm-style-iw-c,
  .gm-style .gm-style-iw-d {
    min-width: auto !important;
    max-width: 200px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .HouseImage {
    position: relative;
    max-height: 100px !important;
  }
  .houseLink {
    left: 0;
  }
  .houseLink span {
    top: 50%;
  }
}

@media (min-width: 992px) {
  .cardOnMap .h5 {
    font-size: 1.25rem !important;
  }
  .cardOnMap p {
    font-size: 14px !important;
  }
  .cardOnMap a {
    font-size: 1.5rem !important;
  }
  .gm-style-iw .gm-style-iw-c {
    max-width: 336px !important;
  }
  .gm-style .gm-style-iw-c,
  .gm-style .gm-style-iw-d {
    min-width: 480px !important;
  }
  .HouseImage {
    max-height: none !important;
  }
}
