@import '../../variables.scss';

.headingContainer {
    height: 85px;
    margin-bottom: 125px;
    .headingImgContainer {
      width: 79px;
      height: 70px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        height: 100%;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        // background-color: #fff;
        background-image: linear-gradient(transparent, #fdfdff);
        // opacity: 0.9;
      }
    }
    &.featuredListingsHeaderImgCon {
      margin-bottom: 108px;
      .headingImgContainer {
        img {
          opacity: 0.7;
        }
        &::after {
          background-image: linear-gradient(transparent, #f7f9ff);
        }
      }
    }
  }
  .headingText {
    position: relative;
    padding-top: 54px;
    font-size: 31.91px;
    font-weight: bold;
    word-spacing: 11px;
    color: $text;
    margin: 0;
    // padding-top: 114px;
    // margin-bottom: 114px;
    // font-family: 'Montserrat';
    // background-image: url('../../assets/landingPage/headerBackGroundLogo2.png');
    // background-repeat: no-repeat;
    // background-position: center bottom 18px;
    // background-size: 100px 75px;
}

// .landingCardContainer {
//   padding: 0;
//   justify-content: center;
//   &:first-child {
//     justify-content: flex-start;
//     .landingCardStyle {
//       margin: 0 !important;
//     }
//   }
//   &:last-child {
//     justify-content: flex-end;
//     .landingCardStyle {
//       margin-right: 0 !important;
//     }
//   }
// }