

.makeItSticky {
    height: 100vh;
    overflow-y: scroll;
    img {
      top: 20px;
    }
}
.makeItSticky::-webkit-scrollbar {
    display: none;
}

.makeItSticky {
    -ms-overflow-style: none;
}

@media (max-width: 768px) {
    .mainOptionsFixMob {
        img {
        width: 70%;
        }
    }
}