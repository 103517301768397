@import '../../variables.scss';

.Filterbar {
  background-color: #fff;
  padding: 1.5rem 1rem;
  margin-bottom: 30px;
  box-shadow: 0px 30px 12px -5px rgba(240, 240, 248, 1);
  .formInline {
    width: 100%;
  }
  .optionsContainer {
    position: relative;
    display: inline-block;
    margin-right: 0.3rem;
    button {
      min-height: 60px;
      width: 100%;
      background-color: #fff;
      border: 1px solid #dbdce3;
      font-size: 13px;
      padding: 0 0.5rem;
      border-radius: 3px;
      transition: 0.3s all ease;
      svg {
        margin-right: 18px;
        fill: $primary;
        margin-top: -3px;
      }
      img {
        margin-right: 18px;
      }
      span {
        color: $text;
        font-weight: bold;
        letter-spacing: -0.02em;
        &.resetFormBtn {
          opacity: 0.2;
          z-index: -1;
          font-weight: 400;
          color: $primary;
          position: absolute;
          right: 10px;
          width: 11px;
          height: 11px;
          transition: 0.3s opacity ease;
          svg {
            width: 100%;
            height: 100%;
            path {
              fill: #bcbcd1;
            }
          }
        }
      }
      &:focus {
        outline: none;
      }
      &.propertyType {
        // To avoid collapse when choosing property with short name
        // min-width: 150px;
        // width: 172px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      &.locationToggleBtn {
        // min-width: 150px;
        // width: 132px;
        svg,
        img {
          width: 16px;
          height: 16px;
          margin-right: 17px;
        }
      }
      &.priceToggleBtn {
        // min-width: 150px;
        // min-width: 169px;
        // width: auto;
        svg,
        img {
          width: 16px;
          height: 16px;
          margin-right: 17px;
        }
      }
      &.spaceToggleBtn {
        min-width: 150px;
      }
      &.bathsToggleBtn {
        min-width: 150px;
      }
      &.deliveryToggleBtn {
        min-width: 150px;
      }
      &.active {
        border-bottom: 1px solid transparent;
      }
      &.triggered {
        background-color: $secondary;
        border: 1px solid transparent;
        padding-right: 25px;
        svg {
          margin-right: 10px;
        }
        span {
          &.resetFormBtn {
            opacity: 1;
            z-index: 1;
          }
        }
      }
    }
    .moreFilterBtn {
      // width: 164px;
      background-color: $primary;
      border: 1px solid transparent;
      box-shadow: 0px 10px 24px 0px rgba(36, 25, 190, 0.25);
      svg,
      img {
        width: 16px;
        height: 16px;
        margin-right: 17px;
      }
      span {
        color: #fff;
      }
    }
    .fixApplyBtn {
      background-color: #2419be;
    }
  }
  .searchOptions {
    position: absolute;
    background-color: #fff;
    width: 100%;
    margin-top: -1px;
    left: 0;
    padding: 0.5rem 1rem;
    // border: 1px solid $grey;
    border: 1px solid #dbdce3;
    border-top: none;
    z-index: 10001;
    input,
    select,
    option {
      line-height: 0.5em;
      height: 1.5em;
      border: none;
      border-radius: inherit;
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
    input {
      background-color: $secondary;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        position: relative;
        margin-bottom: 5px;
        padding-top: 4px;
        input {
          opacity: 0;
          z-index: 1;
          position: absolute;
          cursor: pointer;
        }
        span {
          position: absolute;
          top: 3px;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: transparent;
          border-radius: 5px;
          border: 1px solid $grey;
          &::after {
            position: absolute;
            content: '';
            left: 12px;
            top: 12px;
            height: 0;
            width: 0;
            border-radius: 5px;
            border: solid #fff;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(0deg) scale(0);
            -ms-transform: rotate(0deg) scale(0);
            transform: rotate(0deg) scale(0);
            opacity: 1;
          }
        }
        p {
          padding-left: 2rem;
          margin-bottom: 0;
          line-height: 1.2;
          overflow-wrap: break-word;
          font-size: 14px;
        }
      }
      li input:checked ~ span {
        background-color: $primary;
        border-radius: 5px;
        -webkit-transform: rotate(0deg) scale(1);
        -ms-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
        opacity: 1;
        border: 1px solid $primary;
        &::after {
          -webkit-transform: rotate(45deg) scale(1);
          -ms-transform: rotate(45deg) scale(1);
          transform: rotate(45deg) scale(1);
          opacity: 1;
          left: 6px;
          top: 1px;
          width: 4px;
          height: 12px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          border-radius: 0;
        }
      }
    }
  }
  .searchForLocations {
    button {
      // margin-top: 0;
      width: 100%;
      border: none;
      height: 35px;
      // margin: 10px 0 0;
      padding: 0;
      text-align: left;
      position: relative;
    }
    button::after {
      // font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f05e";
      content: '\f107';
      color: #333;
      // top: 3px;
      right: 0px;
      position: absolute;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
    }
    button[aria-expanded='true']::after {
      content: '\f106';
    }
    .locationInput {
      font-size: 12px;
      height: 35px;
      margin-bottom: 10px;
      border-bottom: 1px solid #dadbe2;
    }
    li {
      cursor: pointer;
    }
    .cityPlacesList {
      margin-bottom: 0;
    }
    .city {
      font-weight: 600;
      color: $text;
    }
    .cityPlace {
      padding-left: 6px;
      color: $text;
      font-size: 12px;
      margin-bottom: 2px;
    }
  }
  .fromTo {
    input {
      padding-left: 5px;
      font-size: 10px;
      height: 20px;
    }
    .spaceInputsContainer {
      padding: 0;
      .priceFrom,
      .priceTo {
        button {
          width: 48%;
          padding: 0;
          margin-top: 2px;
          &:last-child {
            float: right;
          }
        }
      }
      .priceFrom {
        padding-right: 5px;
      }
      .priceTo {
        padding-left: 5px;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
      .SpaceButtonsContainer {
        width: 100%;
        margin: auto;
        margin-bottom: 15px;
        div {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
  .priceRange {
    width : 203%;
    .priceSearchPaymentType {
      position: relative;
      input {
        opacity: 0;
        position: absolute;
        cursor: pointer;
      }
      span {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid $grey;
        &::after {
          position: absolute;
          content: '';
          left: 12px;
          top: 12px;
          height: 0;
          width: 0;
          border-radius: 5px;
          border: solid #fff;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(0deg) scale(0);
          -ms-transform: rotate(0deg) scale(0);
          transform: rotate(0deg) scale(0);
          opacity: 1;
        }
      }
      p {
        padding-left: 25px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .priceSearchPaymentType input:checked ~ span {
      background-color: $primary;
      border-radius: 5px;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 1px solid $primary;
      &::after {
        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
        opacity: 1;
        left: 6px;
        top: 1px;
        width: 4px;
        height: 12px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        background-color: transparent;
        border-radius: 0;
      }
    }
    .installmentSection {
      margin-bottom: 15px;
      .installmentRangeInputsContainer {
        color: $primary;
        .installmentInputContainer {
          padding: 0;
          input {
            padding-left: 5px;
          }
          &:first-child {
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 12px;
          margin-bottom: 0;
        }
        input {
          height: 30px;
          font-size: 12px;
        }
      }
      &.disabled {
        color: $grey;
        ::-webkit-input-placeholder {
          color: $grey;
        }
        :-moz-placeholder {
          color: $grey;
          opacity: 1;
        }
        ::-moz-placeholder {
          color: $grey;
          opacity: 1;
        }
        :-ms-input-placeholder {
          color: $grey;
        }
        ::-ms-input-placeholder {
          color: $grey;
        }

        ::placeholder {
          color: $grey;
        }
        p {
          color: $grey;
        }
      }
    }
    .cashSection {
      > div {
        &:nth-child(2) {
          width: 90%;
          margin: auto;
        }
      }
      .cashScaleValues {
        color: #a59ff2;
        text-align: center;
        span {
          font-weight: bold;
          font-size: 1.3rem;
          color: #2419be;
        }
      }
      &.disabled {
        color: $grey;
      }
    }
  }
  .deliverySearch {
    p {
      padding-left: 30px !important;
    }
  }
  .sortedBy {
    height: 60px;
    position: relative;
    .fixedText {
      font-size: 11px;
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      font-weight: bold;
    }
    .dropdown {
      width: 100%;
      height: 100%;
      .dropdownBtn {
        width: 100%;
        height: 100%;
        text-align: right !important;
        font-size: 14px;
        font-weight: bold;
        color: $text;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #dbdce3;
        border-radius: 3px;
        padding: 0 0.5rem;
        // width: 50%;
        &:focus {
          outline: none;
        }
      }
      .dropdownMenu {
        width: 100%;
        border-top: none;
        border-radius: 0;
        .dropdownItem {
          cursor: pointer;
        }
      }
    }
  }
  .customView {
    height: 60px;
    display: flex;
    border: 1px solid #dbdce3;
    button {
      border: none;
      background-color: #fff;
      transition: 0.2s all ease;
      padding: 8px 8px;
      // margin: 0 3px;
      // width: 50%;
      flex-grow: 1;
      // height: 100%;
      svg {
        path {
          fill: $primary;
        }
      }
      &:focus {
        outline: none;
      }
      &.activatedSwitchBtn {
        background-color: $primary;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}
// @media screen and (max-width: 1919px) {
//   .Filterbar {
//     width: 91vw;
//   }
// }.

@media (max-width: 992px) {
  .filter {
    .body {
      .rangeNumberFixMobLeftSide {
        text-align: left;
      }
      .rangeNumberFixMobRightSide {
        text-align: right;
        padding: 0.5rem 0 !important;
      }
      .rangeSliderFixMob {
        padding-left: 0;
      }
    }
  }
  .Filterbar{
    min-height: auto!important;
    .priceRange {
      width: 100%;
    }
  }
  .optionsContainer {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 5px !important;
    button {
      width: 100% !important;
      text-align: left !important;
      padding-left: 3% !important;
    }
  }
  .sortedBy {
    width: 100% !important;
    margin-right: 0 !important;
    padding: 0 !important;
    .fixedText {
      left: 11px !important;
    }
    button {
      width: 100% !important;
      text-align: left !important;
      padding-left: 3% !important;
    }
  }
  .customView {
    width: 100% !important;
    margin-top: 5px;
    button {
      width: 50% !important;
    }
  }
}


@media (max-width: 1250px) and (min-width: 992px) {
  .Filterbar {
      .searchBtnsContainer {
        flex: 0 0 100%;
        max-width: 100%;
        .optionsContainer {
          margin-right: 0.25rem !important;
          button {
            text-align: center !important;
          }
        }
    }
    .sortedBy {
      flex: 0 0 60%;
      max-width: 60%;
      padding-right: 0.25rem !important;
      margin-top: 0.5rem;
      padding-left: 0;
    }
    .customView {
      flex: 0 0 40%;
      max-width: 40%;
      margin-top: 0.5rem;
    }
  }
}

.clearBtn,
.clearBtn:focus,
.clearBtn:active {
  // min-width: 150px;
  // height: 40px;
  // background-color: #fff;
  // border: 1px solid #bebfc9;
  // padding: 8px 8px;
  // font-size: 14px;
  // margin: 0;
  // transition: 0.3s all ease;
  // outline: none;
  // font-weight: bold;
}

.moreHeader {
  h5 {
    opacity: 0.7;
    width: 100%;
    font-weight: bold;
    font-size: 2rem !important;
    svg {
      font-size: 2rem !important;
      transform: translateY(9px);
    }
  }
}
.closeModal {
  cursor: pointer;
}

.filter {
  * {
    box-sizing: border-box;
  }
  font-size: 29px;
  font-weight: bold;
  color: #202551;
  .label {
    font-size: 29px;
    font-weight: bold;
    color: #202551;
    width: 100%;
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 26px;
    }
  }
  .body {
    width: 100%;
    text-align: right;
    display: block;
    .numbers {
      font-size: 16.49px;
      display: block;
      color: #202551;
      font-weight: bold;
      border: 1px solid #d8d8e2;
      max-width: 150px;
      text-align: center;
      // padding: 19px;
      cursor: pointer;
      border-radius: 1px;
    }
    .numbers:hover {
      background-color: #2419be;
      color: #fff;
    }
    .numberSelect {
      background-color: #2419be;
      color: #fff !important;
    }
    .readyToMove {
      font-size: 12px !important;
      height: 100%;
      display: block;
      color: #202551;
      font-weight: bold;
      border: 1px solid #d8d8e2;
      max-width: 300px;
      text-align: center;
      cursor: pointer;
      border-radius: 1px;
    }
    .readyToMove:hover {
      background-color: #2419be;
      color: #fff;
    }
  } 
  .clearAll {
    color: #202551;
    opacity: 0.5;
  }
}

@media (min-width: 1920px) {
  .sortedBy {
    margin-left: auto;
  }
}

.mobileFilters{
  border: none;
  width: 100%;
  background-color: #2419be;
  color: #fff;
  margin-top: 10px;
  text-align: left;  
  cursor: pointer;
  img{
    width: 20px;
  }
}