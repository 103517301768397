.header {
  color: #2419be;
  font-weight: bold;
  font-size: 37.1px;
}
.paragraph {
  font-size: 14px;
}
.location,
.compund {
  background-color: #ffffff;
  outline: none;
  font-size: 14px;
  select,
  input {
    border: none;
    outline: none;
    height: 60px;
  }
  font-weight: bold;
  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    outline: none;
    line-height: 1;          
    background: url('http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png') no-repeat right #ffffff;
    -webkit-appearance: none;
    background-position: center right 20px;   
  }
}
.input {
  height: 60px;
  // padding: 30px;
  padding-top: 11px;
  font-weight: bold;
  background-color: #ffffff;
  outline: none;
  font-size: 14px;
}

.date {
  background-color: #ffffff;
  font-size: 14px;
  height: 60px;
  font-weight: bold;
  padding: 19px;
  span {
    padding: 0 5px;
    cursor: pointer;
  }
}

.label input {
  display: none;
}

.label {
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}
.label span {
  position: relative;
  line-height: 22px;
}
.label span:before,
.label span:after {
  content: '';
}
.label span:before {
  border: 1px solid #2419be;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}

.label span:after {
  background: #2419be;
  width: 18px;
  height: 19px;
  position: absolute;
  left: 1px;
  transition: 300ms;
  opacity: 0;
}
.label input:checked+span:after {
  opacity: 1;
}